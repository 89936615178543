<template>
    <div>
      <b-sidebar text-variant="light" bg-variant="dark" id="sidebar-1" no-header backdrop shadow>
        <template>
            <!-- <div id="header-sidebar">
                <img src="/assets/images/logo.svg" alt="">
            </div> -->
        </template>
        <nav class="mt-3">
            <b-nav vertical type="light" variant="light">
              <b-nav-item :to="'/order-groups'"><span><font-awesome-icon icon="video" class="icon"/></span> Sesi Live</b-nav-item>
              <b-nav-item v-b-toggle.collapse-order><span><font-awesome-icon :icon="['fa', 'shopping-cart']" class="icon"/></span>Order</b-nav-item>
              <b-collapse id="collapse-order">
                  <b-nav-item :to="'/orders'"><span><font-awesome-icon icon="circle" class="icon"/></span>Listing</b-nav-item>
                  <b-nav-item :to="'/orders/create'"><span><font-awesome-icon icon="circle" class="icon"/></span>Tambah Baru</b-nav-item>
              </b-collapse>
              <b-nav-item v-b-toggle.collapse-product><span><font-awesome-icon :icon="['fab', 'product-hunt']" class="icon"/></span>Produk</b-nav-item>
              <b-collapse id="collapse-product">
                  <b-nav-item :to="'/products'"><span><font-awesome-icon icon="circle" class="icon"/></span>Listing</b-nav-item>
                  <b-nav-item :to="'/products/create'"><span><font-awesome-icon icon="circle" class="icon"/></span>Tambah Baru</b-nav-item>
              </b-collapse>
              <b-nav-item v-b-toggle.collapse-product-category><span><font-awesome-icon icon="archive" class="icon"/></span>Kategori Produk</b-nav-item>
              <b-collapse id="collapse-product-category">
                  <b-nav-item :to="'/product-categories'"><span><font-awesome-icon icon="circle" class="icon"/></span>Listing</b-nav-item>
                  <b-nav-item :to="'/product-categories/create'"><span><font-awesome-icon icon="circle" class="icon"/></span>Tambah Baru</b-nav-item>
              </b-collapse>
              <!-- <b-nav-item v-b-toggle.collapse-4><span><font-awesome-icon icon="credit-card" class="icon"/></span> Akun Bank</b-nav-item>
              <b-collapse id="collapse-4">
                  <b-nav-item :to="'/bank-accounts'"><span><font-awesome-icon icon="circle" class="icon"/></span>Listing</b-nav-item>
                  <b-nav-item :to="'/bank-accounts/create'"><span><font-awesome-icon icon="circle" class="icon"/></span>Tambah Baru</b-nav-item>
              </b-collapse> -->
              <!-- <b-nav-item v-b-toggle.collapse-company><span><font-awesome-icon icon="building" class="icon"/></span>Company</b-nav-item>
              <b-collapse id="collapse-company">
                  <b-nav-item :to="'/companies'"><span><font-awesome-icon icon="circle" class="icon"/></span>Listing</b-nav-item>
                  <b-nav-item :to="'/companies/create'"><span><font-awesome-icon icon="circle" class="icon"/></span>Tambah Baru</b-nav-item>
              </b-collapse> -->
              <b-nav-item v-b-toggle.collapse-customer><span><font-awesome-icon icon="user-tie" class="icon"/></span>Customer</b-nav-item>
              <b-collapse id="collapse-customer">
                  <b-nav-item :to="'/customers'"><span><font-awesome-icon icon="circle" class="icon"/></span>Listing</b-nav-item>
                  <b-nav-item :to="'/customers/create'"><span><font-awesome-icon icon="circle" class="icon"/></span>Tambah Baru</b-nav-item>
              </b-collapse>
              <b-nav-item v-if="$store.getters['account/accountData'].role === 'admin'" v-b-toggle.collapse-3><span><font-awesome-icon icon="users" class="icon"/></span> Staff</b-nav-item>
              <b-collapse v-if="$store.getters['account/accountData'].role === 'admin'" id="collapse-3">
                  <b-nav-item :to="'/users'"><span><font-awesome-icon icon="circle" class="icon"/></span>Listing</b-nav-item>
                  <b-nav-item :to="'/users/create'"><span><font-awesome-icon icon="circle" class="icon"/></span>Tambah Baru</b-nav-item>
              </b-collapse>
              <b-nav-item v-b-toggle.collapse-report><span><font-awesome-icon icon="paperclip" class="icon"/></span>Laporan</b-nav-item>
              <b-collapse id="collapse-report">
                  <b-nav-item :to="'/report/orders'"><span><font-awesome-icon icon="circle" class="icon"/></span>Order</b-nav-item>
              </b-collapse>
              <b-nav-item v-b-toggle.collapse-4><span><font-awesome-icon icon="cog" class="icon"/></span>Pengaturan</b-nav-item>
              <b-collapse id="collapse-4">
                  <b-nav-item :to="'/web-configs/comment-format'"><span><font-awesome-icon icon="circle" class="icon"/></span>Format Komentar</b-nav-item>
                  <b-nav-item :to="'/web-configs/whats-app'"><span><font-awesome-icon icon="circle" class="icon"/></span>WhatsApp</b-nav-item>
              </b-collapse>
              <!-- <b-nav-item v-b-toggle.collapse-6><span><font-awesome-icon icon="users" class="icon"/></span> Staff User</b-nav-item>
              <b-collapse id="collapse-6">
                  <b-nav-item :to="'/resellers/create'"><span><font-awesome-icon icon="circle" class="icon"/></span>Listing</b-nav-item>
                  <b-nav-item :to="'/resellers'"><span><font-awesome-icon icon="circle" class="icon"/></span>Tambah Baru</b-nav-item>
              </b-collapse> -->
            </b-nav>
          </nav>
      </b-sidebar>
      <b-navbar id="large-nav" class="hidden-sm-down" toggleable="lg" type="light" variant="light">
            <b-navbar-brand v-b-toggle.sidebar-1>
                <font-awesome-icon icon="bars" class="icon"/>
            </b-navbar-brand>
            <b-dropdown id="nav-dropdown" right text="Akun" variant="transparent">
                <b-dropdown-item href="#/settings/password">Ubah Password</b-dropdown-item>
                <div class="dropdown-divider"></div>
                <b-dropdown-item @click.prevent="logOut">Sign Out</b-dropdown-item>
            </b-dropdown>
       </b-navbar>
    </div>
</template>

<script>
export default {
    methods: {
        logOut () {
            this.$swal({
                title: 'Anda Yakin Akan Log Out?',
                text: '',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Ya',
                cancelButtonText: 'Tidak',
                confirmButtonClass: 'btn btn-sm btn-danger mr-3',
                cancelButtonClass: 'btn btn-sm btn-secondary',
                buttonsStyling: false
            }).then(result => {
                if (result.value) {
                    this.$store.dispatch('account/logout').then(() => {
                        this.$router.push('/auth/login')
                        // location.reload()
                    }).catch(() => {
                        this.$router.push('/auth/login')
                        // location.reload()
                    })
                }
            })
        }
    },
    mounted () {
        if (this.$webConfig.notification) {
            this.$store.getters['account/firebaseMessaging'].onMessage(payload => {
                // payload.data.title
                // payload.data.body
                // payload.data['gcm.notification.type']
                // payload.data['gcm.notification.type_id']
                // console.log('Message received. ', payload.data['gcm.notification.type_id'])
                // 'addToCart'
                this.$store.dispatch('notification/notification', {
                    title: payload.data.title,
                    message: payload.data.body,
                    redirect: '/#/orders?order_no=' + payload.data['gcm.notification.type_id']
                })
            })
        }
    }
}
</script>